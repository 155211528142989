var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dropdown-type"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "aria-describedby": ariaDescribedby
          },
          model: {
            value: _vm.computedType,
            callback: function callback($$v) {
              _vm.computedType = $$v;
            },
            expression: "computedType"
          }
        }, [!_vm.enableRoundTrip ? _c('b-form-radio', {
          staticClass: "custom-control-warning",
          attrs: {
            "value": "OW",
            "plain": ""
          }
        }, [_c('div', {
          staticClass: "text-body font-medium-1 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.OW')) + " ")])]) : _c('b-form-radio', {
          staticClass: "custom-control-warning",
          attrs: {
            "plain": "",
            "value": "RT"
          }
        }, [_c('div', {
          staticClass: "text-body font-medium-1 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.RT')) + " ")])])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }